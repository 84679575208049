.Home {
  background: linear-gradient(to right, #cbcbcb 0%, #9fc5f7 100%);
  text-align: center;
}

.top-half {
  height: 40vh; /* Adjust height or use min-height for smaller devices */
  background: linear-gradient(to right, #cbcbcb 0%, #9fc5f7 100%);
}

.bottom-half {
  display: flex;
  flex-wrap: wrap; /* Allows child elements to wrap as needed */
  justify-content: space-around; /* Adjusts spacing for varying screen sizes */
  padding: 20px;
  background-color: #e0e0e0;
}

.checkbox-label {
  margin: 5px;
  cursor: pointer;
  display: block;
  text-align: left;
}

.checkbox-group-container {
  border: 1px solid #000000;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

input[type="checkbox"] {
  margin-right: 10px;
}

.card:hover, .group:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.relic-item {
  perspective: 1000px;
  flex: 0 0 auto;
  width: 400px; /* Set a max-width for mobile screens */
  height: 300px;
  margin: 10px;
  text-align: center;
  position: relative;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out;
  box-sizing: border-box;
}

.relic-item:hover {
  transform: scale(1.1);
}

.fissure-card {
  perspective: 1000px;
  flex: 0 0 auto;
  width: 200px; /* Set a max-width for mobile screens */
  height: 230px;
  text-align: center;
  position: relative;
  border-radius: 5px;
  border: 1px solid #000;
}

.relic-item-flipper {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.relic-item.flipped .relic-item-flipper {
  transform: rotateY(180deg);
}

.relic-item-front, .relic-item-back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ddd;
  background-color: #edebeb;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.relic-item-back {
  transform: rotateY(180deg);
  flex-direction: column; /* Stack children vertically */
  justify-content: flex-start; /* Align content to the top */
  text-align: left; /* Align text to the left */
}

.relic-item-drop {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.relic-item-drop-name, .relic-item-drop-platinum .relic-item-drop-volume {
  margin: 5px;
}


.relic-item-labels{
  display: flex;
  justify-content: space-between;
  width: calc(100% - 10px); /* Adjusted width to account for padding */
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding: 5px; /* Added padding */
  background-color: #f9f9f9; /* Slightly different background for distinction */
  margin-bottom: 5px; /* Add space between header and items */
}

.relic-item-label1 {
  flex: 3;
}

.relic-item-label2 {
  flex: 1;
}
.relic-item-label3 {
  flex: 1;
}

/* Ensure that the name has enough space and the price and volume are not squished */
.relic-item-drop-name {
  flex: 3; /* Give the name more space */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.relic-item-drop-platinum, .relic-item-drop-volume {
  flex: 1; /* Ensure price and volume take up equal space */
  flex-shrink: 0;
  text-align: center; /* Align the text to the right */
  padding-left: 5px;
}

.carousel-container {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width:auto;
  scrollbar-color: #252222 #c6cace;
  /* padding: 10px 0; */
  width: 100%;
  height: 100%;
}

.carousel-container::-webkit-scrollbar {
  height: 8px;
}

.carousel-container::-webkit-scrollbar-track {
  background: #e0e0e0;
}

.carousel-container::-webkit-scrollbar-thumb {
  background: #888;
}

.carousel-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .bottom-half-left, .bottom-half-right {
    width: 100%; /* Full width on small screens */
    margin: 0;
  }
  
  .relic-item, .fissure-card {
    width: calc(50% - 20px); /* Two items per row on small screens, accounting for margin */
    height: auto; /* Adjust height as necessary */
  }
  
  .carousel-container {
    padding: 0 10px;
  }
  
  .top-half {
    height: 30vh; /* Adjust top-half height on small screens */
  }
  
  .relic-item {
    width: calc(100% - 20px); /* Full width on very small screens */
  }
}

@media (max-width: 480px) {
  .relic-item, .fissure-card {
    width: calc(100% - 20px); /* Full width on extra small screens */
  }
  
  .bottom-half {
    padding: 10px; /* Less padding on extra small screens */
  }
  
  /* Additional mobile-specific styles if necessary */
}